<template>
    <layout-full-width :title="$tc('billing', 1)">
        <component-sub-navigation :items="subNavigation" />

        <component-actions :action-filters="[]"
                           :itemsPerPage="10"
                           :page="1"
                           :primary-actions="[]"
                           :total="0"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @search="onSearch"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <button class="btn btn-checkbox">
                                    <font-awesome-icon :icon="['far', 'square']"/>
                                </button>
                            </th>
                            <th scope="col">{{ $t('order-number') }}</th>
                            <th scope="col">{{ $tc('service', 1) }}</th>
                            <th scope="col">{{ $tc('date', 1) }}</th>
                            <th scope="col">{{ $t('modified') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colspan="99">
                                <div
                                    class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                    role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('pages.billing.invoices.no-invoices-found') }}</div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../../layouts/LayoutFullWidth";
import ComponentSubNavigation from "../../../components/ui/ComponentSubNavigation";
import ComponentActions from '../../../components/ui/ComponentActions.vue';

export default {
    name: "PageOrders",
    components: { LayoutFullWidth, ComponentActions, ComponentSubNavigation },
    data() {
        return {
            subNavigation: [
                {
                    to: { name: 'account-billing-orders' },
                    label: this.$tc('order', 2)
                },
                {
                    to: { name: 'account-billing-invoices' },
                    label: this.$tc('invoice', 2)
                },
            ]
        }
    },
    methods: {
        onChangePage() {
            console.log('onChangePage');
        },
        onSearch() {
            console.log('onSearch');
        },
        onChangeItemsPerPage() {
            console.log('onChangeItemsPerPage');
        },
        onApplyFilters() {
            console.log('onApplyFilters');
        }
    }
}
</script>
